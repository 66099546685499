.tox-notification {
    display: none!important;
}

.tox-promotion {
    display: none!important;
}

.tox-pop--left {
    display: none!important;
}

.tox-label {
    white-space: pre-wrap!important;
}
