.ant-card {
  @apply rounded-xl !border-none;

  box-shadow: 0 0 0 1px #dce1e6;
}

.ant-card-head {
  @apply px-3 xl:px-5;
}

.ant-card-body {
  @apply p-3 xl:p-5;
}


@media (min-width: 0) and (max-width: 627px) {
  .ant-card-head-wrapper{
    @apply !flex-wrap
  }

  .ant-card-extra{
    @apply m-0 pt-0
  }

  .ant-card-head-title{
    @apply m-0 pb-0 !whitespace-normal min-w-fit
  }
}

@media (min-width: 0) and (max-width: 467px) {
  .ant-card-extra{
    @apply m-1;
  }
}
