.collapsedMenu {
  @apply w-64 xl:!w-14 !translate-x-0 !px-1.5;
}

.badge {
  @apply text-white px-1.5 text-[12px] rounded bg-red absolute top-1/2 -translate-y-1/2 right-1 ;
}

.link {
  @apply p-3 pr-3.5 rounded-md relative flex justify-between items-center text-[#ffffffe6] hover:bg-[#1d232a] hover:text-gray max-m595:p-1 max-m595:pr-1.5;
}

.linkActive {
  @apply bg-[#191e24] text-white hover:text-white;
}

.linkActive span{
  text-shadow: 0 0 8px rgb(255 255 255 / 80%);
}

.linkActive svg {
  @apply text-white;
}

.text {
  @apply ml-4;
}

@media (min-width: 1280px) {
  .collapsedMenu .logo {
    @apply !p-0;
  }

  .collapsedMenu .logo img {
    @apply !w-11 !h-11;
  }

  .collapsedMenu .text {
    @apply hidden;
  }

  .collapsedMenu .arrow {
    @apply rotate-180;
  }

  .collapsedMenu .link {
    @apply !px-1 !justify-center;
  }

  .collapsedMenu .linkActive {
    @apply justify-center;
  }

  .collapsedMenu .badge {
    @apply -right-1 text-white top-2 px-1;
  }
}
